























































































import { Editor } from '@tiptap/core';
import {EditorContent} from '@tiptap/vue-2';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Blockquote from '@tiptap/extension-blockquote';
import CodeBlock from '@tiptap/extension-code-block';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import History from '@tiptap/extension-history';
import Color from '@tiptap/extension-color';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import TextStyle from '@tiptap/extension-text-style';

@Component({
  components: { EditorContent },
})
export default class TextEditor extends Vue {
  @Prop({ default: null })
  public value!: string | null;

  @Prop({ default: null })
  public initial!: string | null;

  @Prop({ default: '<p>Beschreibung des Spielabends</p>' })
  public placeholderText!: string;

  private editor: Editor | null = null;
  private selectedColor = 'Standard';
  private colors = [
    { text: 'Rot', value: 'red' },
    { text: 'Gelb', value: 'yellow' },
    { text: 'Grün', value: 'green' },
    { text: 'Blau', value: 'lightblue' },
    { text: 'Cyan', value: 'cyan' },
    { text: 'Pink', value: 'pink' },
  ];
  private supressUpdate = false;
  private innerValue = '';

  public mounted() {
    const content = this.initial ? this.initial : this.placeholderText;
    Heading.configure({
      levels: [1, 2, 3],
    });
    this.editor = new Editor({
      content,
      extensions: [
        Document,
        Paragraph,
        Text,
        TextStyle,
        Blockquote,
        BulletList,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        ListItem,
        OrderedList,
        Bold,
        Code,
        Italic,
        Strike,
        Underline,
        History,
        Color,
      ],
      onUpdate: ({editor}) => {
        this.$emit('input', editor.getHTML());
        this.innerValue = editor.getHTML();
      },
    });

    // Send initial event
    this.$emit('input', content);
    this.innerValue = content;
  }

  public reset() {
    if (this.editor !== null) {
      this.editor?.setOptions({
        content: this.placeholderText,
      });
    }
  }

  public beforeDestroy() {
    if (this.editor !== null) {
      this.editor.destroy();
    }
  }

  public getContent() {
    return this.innerValue;
  }

  @Watch('initial')
  public onValueChange(newValue) {
    this.editor?.setOptions({
      content: newValue,
    });
  }
}
